import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import jwt_decode from "jwt-decode";

@Injectable({
    providedIn: "root",
})
export class ApiService {
    private initUrl: string;
    private url: string;
    private tokenName: string;
    private requestType: string;
    private headerOptions: any;
    private data: any;
    private params: HttpParams;
    private useToken: boolean = false;
    private keyTokenName: string;
    private subscriptionTokenName: string;
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    public userVerified: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public userSubscribed: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public userSubscribedObservable: Observable<boolean> =
        this.userSubscribed.asObservable();

    public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public userObservable: Observable<any> = this.userSubject.asObservable();

    constructor(private http: HttpClient) {
        this.initUrl = environment.apiUrl;
        this.tokenName = environment.tokenName;
        this.keyTokenName = environment.keyTokenName;
        this.subscriptionTokenName = environment.subscriptionTokenName;
    }

    get userDetail(): any {
        const user = this.user ? JSON.parse(this.user) : null;
        if (!user) {
            return "";
        }

        return user;
    }

    get isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    get isUserVerified(): Observable<boolean> {
        return this.userVerified.asObservable();
    }

    get isUserSubscribed(): boolean {
        let subsToken: any = localStorage.getItem(this.subscriptionTokenName);
        let subs = JSON.parse(subsToken);
        this.userSubscribed.next(subs);

        return JSON.parse(subsToken);
    }

    set isUserSubscribed(isUserSubscribed: boolean) {
        this.userSubscribed.next(isUserSubscribed);

        let data = JSON.stringify(isUserSubscribed);
        localStorage.setItem(this.subscriptionTokenName, data);
    }

    public get user(): string {
        let user = localStorage.getItem(this.tokenName);
        let parsedUser = JSON.parse(user);
        this.userSubject.next(parsedUser);

        return localStorage.getItem(this.tokenName);
    }

    public set user(user: string) {
        let parsedUser = JSON.parse(user);
        this.userSubject.next(parsedUser);

        localStorage.setItem(this.tokenName, user);
    }

    public get keyPair(): string {
        return localStorage.getItem(this.keyTokenName);
    }

    public set keyPair(keypair: string) {
        localStorage.setItem(this.keyTokenName, keypair);
    }

    isUserLoggedIn(): boolean {
        const user = this.user ? JSON.parse(this.user) : null;

        if (!user) {
            return false;
        }
        const token = !!user.access_token;
        if (token) {
            const t: any = jwt_decode(user.access_token);
            const d = new Date(t.exp * 1000);
            const curDate = new Date();

            if (curDate > d) {
                return false;
            } else {
                this.loggedIn.next(true);
                return true;
            }
        } else {
            return false;
        }
    }

    getMembershipPlans() {
        this.url = `subscriptions/plans`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    // body : customerId
    showAllSubscriptions(data: any) {
        this.url = `subscriptions/all`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    // body : customerId
    getStripeCustomerDetails(data: any) {
        this.url = `subscriptions/retrieve-customer`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    //  body : customerId, priceId
    startSubscription(data: any) {
        this.url = `subscriptions/create-subscription`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    //  body: subscriptionId, priceId
    updateSubscription(data: any) {
        this.url = `subscriptions/update-subscription`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    // body :subscriptionId
    cancelSubscription(data: any) {
        this.url = `subscriptions/cancel-subscription`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    createPayment(data: any) {
        this.url = `subscriptions/payment`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    archiveProject(projectId) {
        this.url = `projects/${projectId}/archive`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    invalidateDocument(documentId) {
        this.url = `documents/${documentId}/invalidate`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    getProjects(data: any) {
        this.url = `projects`;
        this.params = new HttpParams()
            .set("documentsToSkip", data.documentToSkip)
            .set("userId", data.userId)
            .set("limitOfDocuments", data.documentCount)
            .set("search", data.filter.projectName)
            .set("status", data.filter.status)
            .set("startDate", data.filter.startDate)
            .set("endDate", data.filter.endDate);
        this.useToken = true;
        this.requestType = "GET_WITH_PARAMS";

        return this.sendHttpRequest();
    }

    getDocumentData(documentId, type) {
        this.url = `documents/${documentId}/download/${type}`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    createProject(data: any) {
        this.url = `projects`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST_WITHOUT_HEADERS";

        return this.sendHttpRequest();
    }

    editProject(data: any) {
        this.url = `edit-project`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST_WITHOUT_HEADERS";

        return this.sendHttpRequest();
    }

    getProjectDetails(projectId) {
        this.url = `projects/${projectId}`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    // recieving docs in base64 format
    // sendDocumentsToSign(data: any) {
    //   this.url = `documents/encrypt`;
    //   this.useToken = true;
    //   this.data = data;
    //   this.requestType = "POST";

    //   return this.sendHttpRequest();
    // }

    // Sending encrypted documents
    sendEncryptedDocuments(data: any) {
        this.url = `documents/decrypt`;
        this.useToken = true;
        this.data = data;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    sendEmail(data: any) {
        this.url = `send/email`;
        this.useToken = true;
        this.data = data;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    // =============================================================
    // ADMIN
    // =============================================================

    getAdminDashboard() {
        this.url = `admin/dashboard`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    getAdminUsers(data: any) {
        // {data:{search:'', documentsToSkip: num, limitOfDocuments: num, status: string}}
        this.url = `admin/users`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    // activate or suspend User
    updateUserStatus(userId: any, status: any) {
        this.url = `admin/users/${userId}/${status}`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    checkUserSubscription(userId: any) {
        this.url = `admin/users/${userId}/subscriptions`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    cancelUserSubscription(userId: any) {
        this.url = `admin/users/${userId}/subscription/cancel`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    getAdminProjects(data: any) {
        // {data:{userId:"", search: "". startDate: "",. endDate: "", documentsToSkip: "", limitOfDocuments: "", status:num}}
        this.url = `admin/projects`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    getAdminPayments(data: any) {
        // {data:{search: {customer_id: "", payment_status: ""}, previous: "", next: "" count: "", status:""}}
        this.url = `admin/payments`;
        this.data = data;
        this.useToken = true;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    getAdminProjectDetails(projectId: any) {
        this.url = `admin/projects/${projectId}`;
        this.useToken = true;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    // =============================================================
    // without AUTH
    // =============================================================
    getDocumentDataWithoutAuth(documentId, type) {
        this.url = `msigner/documents/${documentId}/download/${type}`;
        this.useToken = false;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    getProjectDetailsWithoutAuth(projectId) {
        this.url = `msigner/projects/${projectId}`;
        this.useToken = false;
        this.requestType = "GET";

        return this.sendHttpRequest();
    }

    // Sending encrypted documents
    sendEncryptedDocumentsWithoutAuth(data: any) {
        this.url = `msigner/documents/decrypt`;
        this.useToken = false;
        this.data = data;
        this.requestType = "POST";

        return this.sendHttpRequest();
    }

    private sendPostRequest() {
        const url = `${this.initUrl}${this.url}`;
        console.info("Post Data", this.data);
        return this.http.post(url, this.data, { headers: this.headerOptions });
    }

    private sendPostWithoutHeadersRequest() {
        const url = `${this.initUrl}${this.url}`;
        console.info("Post Data", this.data);

        return this.http.post(url, this.data, { headers: this.headerOptions });
    }

    private sendGetRequest() {
        const url = `${this.initUrl}${this.url}`;
        return this.http.get(url, { headers: this.headerOptions });
    }

    private sendGetRequestWithParams() {
        const url = `${this.initUrl}${this.url}`;
        return this.http.get(url, {
            headers: this.headerOptions,
            params: this.params,
        });
    }

    private sendPutRequest() {
        const url = `${this.initUrl}${this.url}`;
        return this.http.put(url, this.data, { headers: this.headerOptions });
    }

    private sendDeleteRequest() {
        const url = `${this.initUrl}${this.url}`;
        return this.http.delete(url, { headers: this.headerOptions });
    }

    private sendHttpRequest() {
        this.headerOptions = this.createRequestOptions();

        switch (this.requestType) {
            case "GET":
                return this.sendGetRequest();
            case "GET_WITH_PARAMS":
                return this.sendGetRequestWithParams();
            case "POST":
                return this.sendPostRequest();
            case "PUT":
                return this.sendPutRequest();
            case "DELETE":
                return this.sendDeleteRequest();
            case "POST_WITHOUT_HEADERS":
                return this.sendPostWithoutHeadersRequest();
        }
    }

    private createRequestOptions() {
        var headerJson = {};
        if (this.requestType != "POST_WITHOUT_HEADERS") {
            Object.assign(headerJson, {
                "Content-Type": "application/json",
            });
        }

        if (this.useToken) {
            const user = JSON.parse(this.user);
            Object.assign(headerJson, {
                Authorization: `bearer ${user.access_token}`,
            });
        }

        const headers = new HttpHeaders(headerJson);
        return headers;
    }
}
