import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import {
    PRICING_PAGE_ROUTER,
    SIGN_IN_PAGE_ROUTER,
    MY_DOCUMENT_PAGE_ROUTER,
    PROJECT_PAGE_ROUTER,
    ROUTING,
} from "src/app/constants/constants";
import { User } from "src/app/models";
import { PricingPlans } from "src/app/models/pricing-plan";
import { ActiveSubscriptionService } from "src/app/services/active-subscription.service";
import { AlertService } from "src/app/services/alert.service";
import { ApiService } from "src/app/services/api.service";
import { LoaderService } from "src/app/services/loader.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    isAuthHeader: boolean = null;
    show = false;
    userName = null;
    userImg;
    currentRoute;
    ROUTING = ROUTING;
    SIGN_IN_PAGE_ROUTER = SIGN_IN_PAGE_ROUTER;
    PRICING_PAGE_ROUTER = PRICING_PAGE_ROUTER;
    MY_DOCUMENT_PAGE_ROUTER = MY_DOCUMENT_PAGE_ROUTER;
    PROJECT_PAGE_ROUTER = PROJECT_PAGE_ROUTER;
    showDropdown: boolean = false;
    user: User;
    isEmailSupport: boolean = false;

    constructor(
        private router: Router,
        private apiService: ApiService,
        private loader: LoaderService,
        private alert: AlertService,
        private activeSubscriptionService: ActiveSubscriptionService
    ) {
        this.user = this.apiService.userDetail;

        this.apiService.userObservable.subscribe((value) => {
            this.userName = value ? value.firstName + " " + value.lastName : "";
            this.userName = value?.id
                ? this.userName
                : "Welcome, " + this.userName;
            this.userImg = value ? value.logo : "";
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                this.currentRoute = event.url;
                if (
                    this.currentRoute.includes(SIGN_IN_PAGE_ROUTER.SIGN_IN) ||
                    this.currentRoute.includes(ROUTING.SIGNER)
                ) {
                    this.isAuthHeader = true;
                } else {
                    this.isAuthHeader = false;
                }
            });

        if (this.user && this.user.id) {
            this.getPlans();
        }
    }

    get isUserSubscribed() {
        let val;
        val = this.apiService.isUserSubscribed;
        return val;
    }

    ngOnInit(): void {}

    toggle() {
        this.show = !this.show;
    }

    mouseOver(event) {
        event.stopPropagation();
        this.showDropdown = true;
    }

    mouseOut(event) {
        event.stopPropagation();
        var e = event.toElement || event.relatedTarget;
        if (e.parentNode == this || e == this) {
            return;
        } else this.showDropdown = false;
    }

    logout() {
        localStorage.clear();
        this.router.navigate([SIGN_IN_PAGE_ROUTER.SIGN_IN]);
    }

    getPlans() {
        this.apiService.getMembershipPlans().subscribe(
            (response: any) => {
                if (response.status) {
                    let pricing = new PricingPlans(response.data);
                    let packages = pricing.plans;
                    this.hasSubscriptions(packages);
                }
            },
            (err) => {
                let error = "There was some error. Please try again later!";
                console.log(error);
                this.loader.stopGlobalLoader();
                this.alert.error(error);
            }
        );
    }

    hasSubscriptions(packages) {
        const data = { customerId: this.user.customerId };

        this.apiService.showAllSubscriptions(data).subscribe(
            (response: any) => {
                if (response.subscriptions) {
                    let activeSubscription = response.subscriptions.data.filter(
                        (item) => item.status == "active"
                    )[0];

                    if (activeSubscription) {
                        let activePlan = packages.filter(
                            (plan) =>
                                plan.planId_monthly ==
                                    activeSubscription.plan?.id ||
                                plan.planId_yearly ==
                                    activeSubscription.plan?.id
                        )[0];

                        this.activeSubscriptionService.activePlan = {
                            activeSubs: activeSubscription,
                            plan: activePlan,
                        };

                        activePlan = this.activeSubscriptionService.activePlan;
                        this.isEmailSupport =
                            activePlan?.plan?.features?.emailSupport;
                    }
                }
                this.loader.stopGlobalLoader();
            },
            (err) => {
                let error = "There was some error. Please try again later!";
                console.log(error);
                this.loader.stopGlobalLoader();
                this.alert.error(error);
            }
        );
    }
}
