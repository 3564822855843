import { Injectable } from "@angular/core";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { ApiService } from "./api.service";
import { PRICING_PAGE_ROUTER, ROUTING } from "../constants/constants";
import { AuthGuard } from "./auth.guard";

@Injectable({
    providedIn: "root",
})
export class SubscriptionGuard implements CanActivate {
    constructor(
        private apiService: ApiService,
        private router: Router,
        private authGuard: AuthGuard
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return this.authGuard
            .canActivate(route, state)
            .then((auth: boolean) => {
                if (!auth) {
                    const route = `/${ROUTING.AUTHENTICATION}`;
                    if (state.url == "/") {
                        this.router.navigate([route]);
                    } else {
                        this.router.navigate([route], {
                            queryParams: { returnUrl: state.url },
                        });
                    }
                    return false;
                } else {
                    if (this.apiService.isUserSubscribed) {
                        return true;
                    } else {
                        let route = PRICING_PAGE_ROUTER.PRICING;
                        this.router.navigate([route]);
                        return false;
                    }
                }
            });
    }
}
