import { Component, Input, OnInit } from "@angular/core";
import { Project } from "src/app/models";
import { ProjectFlowService } from "src/app/services/project-flow.service";

@Component({
  selector: "app-project-steps",
  templateUrl: "./project-steps.component.html",
  styleUrls: ["./project-steps.component.scss"],
})
export class ProjectStepsComponent implements OnInit {
  projectStages: Project[];
  @Input() isEditProject: boolean = false;

  constructor(private projectFlowService: ProjectFlowService) {
    this.projectStages = this.projectFlowService.projectFlowValue;
  }

  ngOnInit(): void {}
}
