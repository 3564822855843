export class Document {
  id: string;
  name: string;
  file: string;
  creator: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  signedFileUrl: string;
  signedUsers: Array<any>;
  isDeleted: number;
  signedUsersNameList: any;

  constructor(data) {
    this.id = data._id ? data._id : "";
    if (data.document_name) {
      let nameSplit = data.document_name.split("/");
      this.name = nameSplit[1] ? nameSplit[1] : nameSplit[0];
    }
    this.file = data.file_url;

    this.createdAt = data.created_date;
    this.updatedAt = data.updated_date;

    this.creator =
      data.creator && data.creator.length > 0
        ? data.creator[0].first_name + " " + data.creator[0].surname
        : "";

    switch (data.status) {
      case 0:
        this.status = "Pending";
        break;
      case 1:
        this.status = "Completed";
        break;
      case 2:
        this.status = "Invalidated";
        break;
      default:
        this.status = "Pending";
    }

    this.signedFileUrl =
      data.signed_filed_url && data.signed_filed_url != ""
        ? data.signed_filed_url
        : null;

    this.signedUsers =
      data.signed_users && data.signed_users?.length > 0
        ? data.signed_users?.map((item) => ({
            name: item.first_name + " " + item.surname,
            image: item.meta ? item.meta?.profile_pic : "assets/img/avatar.svg",
            oneId: item.oneId,
            email: item.email,
          }))
        : [];
    if (data.signed_users && data.signed_users.length > 2) {
      data.signed_users.splice(0, 2);
      this.signedUsersNameList = data.signed_users.map(function (item) {
        return " " + item.first_name + " " + item.surname;
      });
    } else {
      this.signedUsersNameList = [];
    }
    this.isDeleted = data.is_deleted;
  }
}
