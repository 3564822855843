import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
} from "@danielmoncada/angular-datetime-picker";
import { ElementsModule } from "./elements/elements.module";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { ngxUiLoaderConfig } from "./config/loader.config";
import { LoaderService } from "./services/loader.service";
import { ApiService } from "./services/api.service";
import { AlertService } from "./services/alert.service";
import { HttpClientModule } from "@angular/common/http";
import { AuthGuard } from "./services/auth.guard";
import { LoggedInAuthGuard } from "./services/logged-in.guard";
import { ProjectFlowService } from "./services/project-flow.service";
import { WebSocketService } from "./services/web-socket.service";
import { SubscriptionGuard } from "./services/subscription.guard";
import { ActiveSubscriptionService } from "./services/active-subscription.service";
import { ChartService } from "./services/chart.service";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ElementsModule,
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ],
    providers: [
        HttpClientModule,
        ApiService,
        LoaderService,
        AlertService,
        AuthGuard,
        LoggedInAuthGuard,
        ProjectFlowService,
        WebSocketService,
        SubscriptionGuard,
        ActiveSubscriptionService,
        ChartService,
        Title,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
