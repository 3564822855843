import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Project } from "../models";

@Injectable({ providedIn: "root" })
export class ProjectFlowService {
    private projectFlowSubject: BehaviorSubject<Project[]> =
        new BehaviorSubject<Project[]>([
            {
                number: 1,
                name: "Create Project",
                route: "create-project",
                isCompleted: false,
                isActive: true,
                documents: [],
            },
            {
                number: 2,
                name: "Project Details",
                route: "project-details",
                isCompleted: false,
                isActive: false,
                documents: [],
            },
            {
                number: 3,
                name: "Sign Documents",
                route: "sign-document",
                isCompleted: false,
                isActive: false,
                documents: [],
            },
            // {
            //   number: 4,
            //   name: "Complete",
            //   route: "complete",
            //   isCompleted: false,
            //   isActive: false,
            //   documents: [],
            // },
        ]);

    public projectFlow: Observable<Project[]>;

    constructor() {
        this.projectFlow = this.projectFlowSubject.asObservable();
    }

    public get projectFlowValue(): Project[] {
        return this.projectFlowSubject.value;
    }

    public set projectFlowValue(data) {
        this.projectFlowSubject.next(data);
    }

    setFlow(stageCompleted) {
        if (stageCompleted != 3) {
            this.projectFlowValue[stageCompleted].isActive = true;
            this.projectFlowValue[stageCompleted].isCompleted = false;
            for (let i = 0; i < stageCompleted; i++) {
                if (this.projectFlowValue.length > stageCompleted) {
                    this.projectFlowValue[i].isCompleted = true;
                    this.projectFlowValue[i].isActive = false;
                }
            }
        }

        for (let i = 2; i > stageCompleted; i--) {
            this.projectFlowValue[i].isActive = false;
            this.projectFlowValue[i].isCompleted = false;
        }

        if (stageCompleted == 3) {
            this.projectFlowValue[2].isCompleted = true;
            for (let i = 0; i < stageCompleted - 1; i++) {
                this.projectFlowValue[i].isCompleted = true;
                this.projectFlowValue[i].isActive = false;
            }
        }

        this.projectFlowSubject.next(this.projectFlowValue);
    }
}
