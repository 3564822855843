export class PricingPlans {
    plans: Array<Plan> = [];

    constructor(data) {
        let plan;
        for (let i = 0; i < data.length; i++) {
            plan = new Plan(data[i]);
            this.plans.push(plan);
        }
    }
}

export class Plan {
    id: string;
    packageName: string;
    pricingPerMonth: string;
    pricingPerYear: string;
    description: string;
    planId_monthly: string;
    planId_yearly: string;
    features: any;

    constructor(data) {
        this.planId_monthly = data.price_id_monthly;
        this.planId_yearly = data.price_id_yearly;

        this.id = data._id;
        this.packageName = data.name;
        this.pricingPerMonth = data.price_per_month;
        this.pricingPerYear = data.price_per_year;
        this.description = data.description;

        this.features = data.facility
            ? {
                  signersPerProject: data.facility.members
                      ? Number(data.facility.members)
                      : 1,
                  documentsPerProject: data.facility.document
                      ? Number(data.facility.document)
                      : 1,
                  ccPerproject: data.facility.cc ? Number(data.facility.cc) : 2,
                  projectsAllowedInArchive: data.facility.archive
                      ? Number(data.facility.archive)
                      : 5,
                  emailSupport: data.facility.support ? true : false,
              }
            : {};
    }
}
