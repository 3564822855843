import { Injectable } from "@angular/core";

import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private toast = Swal.mixin({
    position: "top-end",
    showConfirmButton: false,
    timer: 6500,
    timerProgressBar: true,
    backdrop: false,
    toast: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    showCloseButton: true,
    closeButtonHtml:
      '<span style="padding: 8px; font-size: 25px;">&times;</span>',
  });

  constructor() {}

  success(message) {
    return this.toast.fire({
      title: message,
      icon: "success",
    });
  }

  defaultError() {
    return this.toast.fire({
      title: "ERROR! - Technical issues has occurred. Please try again later!",
      icon: "error",
    });
  }

  error(message) {
    return this.toast.fire({
      title: message,
      icon: "error",
    });
  }

  info(message) {
    this.toast.fire({
      title: message,
      icon: "info",
    });
  }
}
