import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ChartService {
  constructor() {}

  get pieChartOptions() {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
      },
    };
  }

  get lineChartOptions() {
    return {
      responsive: true,
      plugins: {
        filler: {
          propagate: false,
        },
      },
      interaction: {
        intersect: false,
      },
    };
  }

  public getLineChartLabels(type, numOfYears = 0) {
    let labels = [];

    switch (type) {
      case "weeks":
        labels = ["Week 1", "Week 2", "Week 3", "Week 4"];
        break;
      case "months":
        labels = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        break;
      default:
        let currentYear = new Date().getFullYear();
        for (let i = numOfYears; i >= 0; i--) labels.push(currentYear - i);
    }

    return labels;
  }

  getChartColors(amount = 1) {
    return ["#1170c9", "#00E0FF", "#34FFDA", "#009c9c"].slice(0, amount);
  }
}
