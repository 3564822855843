import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { io } from "socket.io-client";
import { environment } from "../../environments/environment";
import {
    MY_DOCUMENT_PAGE_ROUTER,
    PRICING_PAGE_ROUTER,
} from "../constants/constants";
import { User } from "../models";
import { ApiService } from "./api.service";
import { LoaderService } from "./loader.service";

@Injectable({
    providedIn: "root",
})
export class WebSocketService {
    private socket: any = io(environment.ws_url);
    QRcode: BehaviorSubject<string> = new BehaviorSubject("");
    qrcodeObservable: Observable<string>;
    isSignScanSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isSignScanSuccessObs: Observable<boolean>;

    isSignSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isSignSuccessObs: Observable<boolean>;

    isSignComplete: BehaviorSubject<any> = new BehaviorSubject(null);
    isSignCompleteObs: Observable<any>;

    userData: BehaviorSubject<any> = new BehaviorSubject({});
    user: User;
    token: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(
        private router: Router,
        private apiService: ApiService,
        private loader: LoaderService,
        private route: ActivatedRoute
    ) {
        this.qrcodeObservable = this.QRcode.asObservable();
        this.isSignScanSuccessObs = this.isSignScanSuccess.asObservable();
        this.isSignSuccessObs = this.isSignSuccess.asObservable();
        this.isSignCompleteObs = this.isSignComplete.asObservable();
    }

    setQRCode(data) {
        this.QRcode.next(data);
    }

    public connectToServer() {
        this.socket.connect();
        this.socket.on("connect", () => {
            console.log("socket connected: ", this.socket.connected);
        });

        this.emitData();
    }

    public emitData() {
        this.socket.emit("createAdminQR", "abc", (data) => {
            console.log("createQR QR code: ", data);
            this.setQRCode(data.data);
            this.loader.stopGlobalLoader();
            this.isLoginVerified();
        });
    }

    public isLoginVerified() {
        this.socket.on("isVerifiedSuccess", (response) => {
            console.log("isVerifiedSuccess: ", response);

            if (response.msg === "success" && response.isUserVerified) {
                this.apiService.userVerified.next(true);
                let userData = {
                    mobileNumber: response.data.mobile_no,
                    nationalityCode: response.data.nationality,
                };

                this.successScan(userData);
            }

            // PENDING
            // deleted request case
        });
    }

    public successScan(userData) {
        this.socket.on("isScanSuccess", (response) => {
            console.log("isScanSuccess: ", response);

            if (response.msg === "success") {
                console.log(response);
                let userDetails = {
                    ...response.data,
                    ...userData,
                    meta: JSON.parse(response.data.meta),
                };

                let user = new User(userDetails);

                // set user data to localstorage
                this.apiService.user = JSON.stringify(user);

                this.apiService.userSubject.next(user);

                if (user.isSubscriptionActive) {
                    this.apiService.isUserSubscribed = true;
                    this.apiService.userSubscribed.next(true);
                } else {
                    this.apiService.isUserSubscribed = false;
                    this.apiService.userSubscribed.next(false);
                }
                const forge = require("node-forge");
                var rsa = forge.pki.rsa;
                try {
                    rsa.generateKeyPair(
                        { bits: 2048, workers: 2 },
                        (err, keypair) => {
                            if (err) throw new Error(err);

                            this.apiService.keyPair = JSON.stringify({
                                publicKey: forge.pki.publicKeyToPem(
                                    keypair.publicKey
                                ),
                                privateKey: forge.pki.privateKeyToPem(
                                    keypair.privateKey
                                ),
                            });

                            // set user data to localstorage
                            this.apiService.user = JSON.stringify(user);

                            // navigate to dashboard on successful scan
                            // this.navigateToDashboard();
                            this.apiService.userVerified.next(false);
                            this.navigateToDashboard();
                        }
                    );
                } catch (err) {
                    console.error("Key Pairs couldn't be generated!", err);
                }
            }
        });
    }

    // signingconnectToServerForSigning
    public connectToServerForSigning(docs) {
        this.socket.connect();
        this.socket.on("connect", () => {
            console.log("socket connected: ", this.socket.connected);
        });

        this.emitSignData(docs);
        // this.isSigningVerified(docs);
    }

    public emitSignData(docs) {
        this.socket.emit("createSignQR", "emitData", (data) => {
            console.log("createSignQR QR code: ", data);
            this.setQRCode(data.data);
            this.isSigningVerified(docs);

            this.loader.stopGlobalLoader();
        });
    }

    public isSigningVerified(docs) {
        this.socket.on("isVerifiedSuccess", (response) => {
            console.log("isVerifiedSuccess: ", response);

            if (response.msg === "success" && response.isUserVerified) {
                this.isSignScanSuccess.next(true);

                // userdata goes here
                this.userData.next(response.data);
                this.signSuccessScan();
            }
        });
    }

    public signSuccessScan() {
        // isSignScanSuccess
        this.socket.on("isSignScanSuccess", (response) => {
            console.log("isSignScanSuccess: ", response);
            if (response.msg === "success") {
                this.isSignSuccess.next(true);

                console.log("Sign successful: ", response);
                this.token.next(response.data.token);
                // response.token
                // response.sessionId
                this.signComplete();
            }
        });
    }

    public signComplete() {
        // isSignScanSuccess
        this.socket.on("signStatus", (response) => {
            console.log("signStatus: ", response);
            if (response.status === true) {
                this.isSignComplete.next(response);
                console.log("Sign complete: ", response);
            }
        });
    }

    public disconnect() {
        this.socket.disconnect();
        this.isSignScanSuccess.next(false);

        // this.apiService.userVerified.next(false);
        console.log("socket connected: ", this.socket.connected); // false

        this.socket.on("disconnect", (reason) => {
            if (reason === "io server disconnect") {
                // the disconnection was initiated by the server, you need to reconnect manually
                this.connectToServer();
            }
        });
    }

    public navigateToDashboard() {
        // =======================================================================
        // if user is subscribed, only then can she be directed to any other route
        // =======================================================================
        if (this.apiService.isUserSubscribed) {
            let returnUrl =
                this.route.snapshot.queryParams["returnUrl"] ||
                MY_DOCUMENT_PAGE_ROUTER.DASHBOARD;

            this.router.navigate([returnUrl]);
        } else {
            this.router.navigate([PRICING_PAGE_ROUTER.PRICING]);
        }
        this.disconnect();
    }
}
