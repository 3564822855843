export const ROUTING = {
    AUTHENTICATION: "login",

    PAGES: "pages",
    MY_PROFILE: "profile",
    PROJECT: "project",
    PROJECTS: "projects",
    SIGNER: "signer",
    USERS: "users",
    PAYMENTS: "payments",
};

export const SIGN_IN_PAGE_ROUTER = {
    SIGN_IN: `/${ROUTING.AUTHENTICATION}`,
};

export const MY_DOCUMENT_PAGE_ROUTER = {
    DASHBOARD: `/`,
};

export const PRICING_PAGE_ROUTER = {
    PRICING: `/${ROUTING.MY_PROFILE}`,
};

export const PROJECT_PAGE_ROUTER = (url, userId?) => {
    switch (url) {
        case "PROJECT_DETAIL":
            return `/${ROUTING.PROJECT}/${userId}`;

        case "PROJECTS_FILTER":
            return `/${ROUTING.PROJECTS}/${userId}`;

        case "PAYMENTS":
            return `/${ROUTING.PAYMENTS}/${userId}`;
    }
};
