import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { ROUTING } from "../constants/constants";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.apiService.isUserLoggedIn()) {
      return new Promise((resolve) => {
        resolve(true);
      });
    } else {
      const route = `/${ROUTING.AUTHENTICATION}`;
      if (state.url == "/") {
        this.router.navigate([route]);
      } else {
        this.router.navigate([route], {
          queryParams: { returnUrl: state.url },
        });
      }
      return new Promise((resolve) => {
        resolve(false);
      });
    }
  }
}
