// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: "https://dev.api.1sign.one/",
    apiKey: "",
    tokenName: "clt56#G25Wr34vab4",
    keyTokenName: "asldj209348",
    subscriptionTokenName: "subs#G25Wr34vab4",
    ws_url: "https://dev.api.1sign.one",
    // ws_url: "http://173.82.105.70:3000",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
