import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ROUTING } from "./constants/constants";
import { LoggedInAuthGuard } from "./services/logged-in.guard";

const routes: Routes = [
    {
        path: "",
        redirectTo: "/",
        pathMatch: "full",
    },

    // other pages
    {
        path: "",
        loadChildren: () =>
            import("./components/pages/pages.module").then(
                (x) => x.PagesModule
            ),
    },
    // auth pages
    {
        path: ROUTING.AUTHENTICATION,
        loadChildren: () =>
            import("./components/authentication/authentication.module").then(
                (x) => x.AuthenticationModule
            ),
        canActivate: [LoggedInAuthGuard],
    },

    // error route
    {
        path: "**",
        redirectTo: "/",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
