import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ActiveSubscriptionService {
  private activePlanDetails: any;
  constructor() {}

  public get activePlan() {
    return this.activePlanDetails;
  }

  public set activePlan({ activeSubs, plan }) {
    this.activePlanDetails = {
      plan: plan,
      planName: plan?.packageName,
      renewalPeriod: activeSubs?.plan?.interval
        ? activeSubs?.plan?.interval == "month"
          ? "monthly"
          : "yearly"
        : "",
      startDate: activeSubs?.current_period_start
        ? new Date(activeSubs?.current_period_start * 1000)
        : "",
      endDate: activeSubs?.current_period_end
        ? new Date(activeSubs?.current_period_end * 1000)
        : "",
    };
  }
}
