import { Document } from "./document";

export class Project {
  id?: string;
  _id?: string;
  name: string;
  creator?;
  signers?: Array<Signer>;
  reviewerSigners?: Array<Signer>;
  ccSigners?: Array<Signer>;
  updatedAt?: string;
  createdAt?: string;

  number: number;
  route: string;
  isCompleted: boolean;
  isActive: boolean;
  documents: Array<Document>;
  status?: string;
  documentsCount?: number;
  signersCount?: number;
  company_id?: number;
  company?: string;

  constructor(data, page?) {
    this.id = data.slug;
    this._id = data._id;
    this.name = data.project_name;
    this.company_id = data.company_id;
    this.reviewerSigners = data.Users
      ? data.Users.filter((item) => item.role == 2).map(
          (item) => new Signer(item, this.documents)
        )
      : [];
    this.ccSigners =
      data.cc && data.cc.length > 0
        ? data.cc.map((item) => new CCSigner(item))
        : [];

    this.creator =
      data.created_by && data.created_by.length > 0
        ? new Creator(data.created_by[0])
        : {};
    this.createdAt = data.created_date;
    this.updatedAt = data.updated_date;

    switch (data.status) {
      case 0:
        this.status = "Pending";
        break;
      case 1:
        this.status = "Completed";
        break;
      case 2:
        this.status = "Deleted";
        break;
      default:
        this.status = "Pending";
    }

    this.documentsCount = data.Documents
      ? data.Documents.filter((item) => item.is_deleted == 0).length
      : 0;
    this.signersCount = data.Users
      ? data.Users.filter((item) => item?.role == 1).length
      : 0;

    let docs = data.Documents
      ? data.Documents.filter((item) => item.is_deleted == 0).map(
          (item) => new Document({ ...item, creator: data.created_by })
        )
      : [];

    this.documents = docs;
    
    if(this.company_id && this.creator.companyList instanceof Array) {
      const company = this.creator.companyList.filter((c) => c.company_id == this.company_id)
      this.company = company[0]?.partner_name || 'Private';
    } else {
      this.company = 'Private';
    }

    if (page == "project-detail-response") {
      this.signers = data.Users
        ? data.Users.filter((item) => item.role == 1).map(
            (item) => new Signer(item, docs)
          )
        : [];
    } else {
      this.signers = data.Users
        ? data.Users.filter((item) => item.role == 1).map(
            (item) => new Signer(item, docs)
          )
        : [];
    }
  }
}

class Signer {
  // id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: any;
  designation: string;
  company: string;
  pendingSignatures: string;
  profileImage: string;

  constructor(data, documents) {
    // this.id = data._id;
    this.email = data.email;
    this.firstName = data.first_name;
    this.lastName = data.surname ? data.surname : "";
    this.designation = data.designation ? data.designation : "-";
    this.company = data.company ? data.company : "-";
    this.role = data.role == 1 ? "Admin" : "Viewer";

    if (documents && typeof documents !== "undefined") {
      let count = documents.length;

      documents.forEach((doc) => {
        let user = doc.signedUsers.find((user) => user.email === data.email);
        if (typeof user !== "undefined") {
          this.profileImage = user.image;
          count--;
        }
      });

      this.pendingSignatures = count === 0 ? "Signed" : `Pending (${count})`;
    }

    if (!this.profileImage) this.profileImage = data.profileImage || null;
  }
}

class CCSigner {
  // id: string;
  email: string;
  firstName: string;
  lastName: string;
  designation: string;

  constructor(data) {
    // this.id = data._id;
    this.email = data.email;
    this.designation = data.designation ? data.designation : "CC user";

    this.firstName = data.name;
    if (data.name.includes(" ")) {
      let nameSplit = data.name.split(" ");
      this.firstName = nameSplit[0];
      this.lastName = nameSplit[1];
    }
  }
}

class Creator {
  // id: string;
  email: string;
  firstName: string;
  lastName: string;
  meta: any;
  oneId: string;
  profilePic: string;
  isAdmin: string;
  companyList: any;

  constructor(creator) {
    // this.id = creator._id;
    this.email = creator.email;
    this.companyList = creator.company_list;
    this.firstName = creator.first_name;
    this.lastName = creator.surname ? creator.surname : "";
    this.profilePic = creator.meta
      ? creator.meta.data.profilePic
      : null;
    this.meta = creator.meta ? creator.meta : "";
    this.oneId = creator.oneId;
    this.isAdmin = creator.is_operator ? 'Operator' : 'Admin';
  }
}
