import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  requests = 0;

  constructor(private ngxLoader: NgxUiLoaderService) {}

  startGlobalLoader() {
    if (this.requests === 0) this.ngxLoader.start();
    else this.requests++;
  }

  stopGlobalLoader() {
    if (this.requests > 1) this.requests--;
    else this.ngxLoader.stop();
  }
}
