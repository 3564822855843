import { NgxUiLoaderConfig } from "ngx-ui-loader";

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#ffffff",
  bgsOpacity: 0.5,
  bgsPosition: "bottom-right",
  bgsSize: 60,
  bgsType: "three-bounce",
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#ffffff",
  fgsPosition: "center-center",
  fgsSize: 60,
  fgsType: "three-bounce",
  // fgsType: "cube-grid",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: false,
  text: "Loading, please wait...",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300,
};
