<!-- <div class="projectStepMain">
  <ul>
    <li class="completed">
      <span> </span>
      <div>Create Project</div>
    </li>
    <li class="completed">
      <span></span>
      <div>Project Details</div>
    </li>
    <li class="active">
      <span>3</span>
      <div>Sign the document</div>
    </li>
    <li>
      <span>4</span>
      <div>Complete</div>
    </li>
  </ul>
</div> -->

<div class="projectStepMain">
  <ul>
    <li
      *ngFor="let stage of projectStages"
      [class.completed]="stage.isCompleted"
      [class.active]="stage.isActive"
    >
      <span *ngIf="stage.isCompleted"></span>
      <span *ngIf="!stage.isCompleted">{{ stage.number }}</span>
      <div>
        {{ isEditProject && stage.number == 1 ? "Edit Project" : stage.name }}
      </div>
    </li>
  </ul>
</div>
