import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ApiService } from "./api.service";
import {
  MY_DOCUMENT_PAGE_ROUTER,
  PRICING_PAGE_ROUTER,
} from "../constants/constants";

@Injectable()
export class LoggedInAuthGuard implements CanActivate {
  constructor(private apiService: ApiService, private router: Router) {}

  canActivate(): boolean {
    if (this.apiService.isUserLoggedIn()) {
      if (this.apiService.isUserSubscribed) {
        let route = MY_DOCUMENT_PAGE_ROUTER.DASHBOARD;
        this.router.navigate([route]);
      } else {
        let route = PRICING_PAGE_ROUTER.PRICING;
        this.router.navigate([route]);
      }

      return false;
    } else {
      return true;
    }
  }
}
