<header *ngIf="isAuthHeader">
    <div class="container">
        <div class="headerInner">
            <div class="logo">
                <a>
                    <img
                    src="../../../assets/img/1_SIGN.png"
                    alt="1:SIGN"
                    height="27px" />
                </a>
            </div>
            <div class="headerRight"></div>
        </div>
    </div>
</header>

<header class="loginHeader" *ngIf="!isAuthHeader">
    <div class="container">
        <div class="headerInner">
            <div class="headerLeft">
                <div class="logo">
                    <a [routerLink]="MY_DOCUMENT_PAGE_ROUTER.DASHBOARD">
                        <img 
                        src="../../../assets/img/1_SIGN.png"
                        alt="1:SIGN"
                        height="27px" />
                    </a>
                </div>
            </div>
            <div class="headerRight dnTab">
                <nav class="nav2">
                    <a
                        [routerLink]="MY_DOCUMENT_PAGE_ROUTER.DASHBOARD"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                    >
                        <!-- <span class="dashboardIcon"></span> -->
                        Dashboard
                    </a>
                    <a [routerLink]="[ROUTING.USERS]" routerLinkActive="active"
                        >Accounts</a
                    >
                    <a
                        [routerLink]="[ROUTING.PROJECTS]"
                        routerLinkActive="active"
                        >Projects</a
                    >
                    <a
                        [routerLink]="[ROUTING.PAYMENTS]"
                        routerLinkActive="active"
                        >Payments</a
                    >
                    <button
                        class="customIconTextBtn customDropdown"
                        (mouseover)="showDropdown = true"
                    >
                        <img
                            *ngIf="!userImg && isUserSubscribed"
                            src="https://dreamvilla.life/wp-content/uploads/2017/07/dummy-profile-pic.png"
                            [alt]="userName"
                        />
                        <img
                            *ngIf="userImg && isUserSubscribed"
                            [src]="userImg"
                            [alt]="userName"
                        />

                        <p>
                            {{ userName ? userName : "" }}
                        </p>

                        <span class="arrowDown"></span>

                        <div
                            class="customDpIn hide"
                            [class.show]="showDropdown"
                            (mouseover)="showDropdown = true"
                            (mouseout)="showDropdown = false"
                        >
                            <a (click)="logout()">Logout</a>
                        </div>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</header>
